<template>
  <section>
    <b-overlay
      :show="data.showLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <div
        v-if="data.showMessage"
      >
        Thank you for checking, but there is no survey to take right now
      </div>
      <div
        v-if="data.showSurveyExternal"
        style="margin:0px;padding:0px;overflow:hidden;height:100vh;width:100%"
      >
        <iframe
          :src="data.url"
          frameborder="0"
          height="100%"
          width="100%"
          style="overflow:hidden;height:100vh;width:100%"
          @load="iframeLoaded"
        />
      </div>
      <b-card
        v-if="data.showSurveyInternal"
        id="surveyInternal"
      >
        <survey :survey="survey" />
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BCard,
} from 'bootstrap-vue'
import * as Survey from 'survey-vue'
import 'survey-vue/modern.css'
import axios from '@axios'

import { useCollapseMenuOnVisit } from '@core/comp-functions/ui/vertical-menu'

Survey.StylesManager.applyTheme('modern')
Survey.defaultBootstrapMaterialCss.navigationButton = 'btn btn-green'
Survey.defaultBootstrapMaterialCss.rating.item = 'btn btn-default my-rating'
function doOnCurrentPageChanged() {
  window.scroll(0, 0)
}

export default {
  components: {
    BOverlay,
    BCard,
  },
  data() {
    const survey = new Survey.Model(null) // initialise so its loaded

    return {
      data: {
        showLoader: true,
        showMessage: false,
        showSurveyInternal: false,
        showSurveyExternal: false,
        type: null,
        url: null,
      },
      survey,
    }
  },
  created() {
    this.getSurvey()
  },
  setup() {
    useCollapseMenuOnVisit()

    return {
      useCollapseMenuOnVisit,
    }
  },
  onBeforeUnmount() {
    useCollapseMenuOnVisit.onBeforeUnmount()
  },
  methods: {
    getSurvey() {
      axios
        .get('/survey/todo')
        .then(response => {
          if (response.data !== null) {
            console.log(response.data)
            if (Object.prototype.hasOwnProperty.call(response.data, 'url')) {
              console.log('external')
              this.data.type = 'external'
              this.data.url = response.data.url
              this.data.showSurveyExternal = true
            } else if (Object.prototype.hasOwnProperty.call(response.data, 'questions')) {
              console.log('internal')
              this.data.type = 'internal'
              this.survey = new Survey.Model(response.data.questions)
              if (Object.prototype.hasOwnProperty.call(response.data, 'answers')) {
                this.survey.currentPageNo = response.data.answers.currentPageNo
                this.survey.data = JSON.parse(response.data.answers.data)
              }
              this.data.showLoader = false
              this.data.showMessage = false
              this.data.showSurveyInternal = true
              this.data.showSurveyExternal = false
              this.data.url = null
              this.survey.onCurrentPageChanged.add(result => {
                const payload = {
                  completed: false,
                  currentPageNo: result.currentPageNo,
                  results: result.data,
                  surveyId: response.data.surveyId,
                }
                this.saveSurvey(payload)
              })
              this.survey.onComplete.add(result => {
                const payload = {
                  completed: true,
                  currentPageNo: result.currentPageNo,
                  results: result.data,
                  surveyId: response.data.surveyId,
                }
                this.saveSurvey(payload)
              })
              this.survey.onCurrentPageChanged.add(doOnCurrentPageChanged)
            } else {
              console.log('option not found')
              this.data.showLoader = false
              this.data.showMessage = true
              this.data.showSurveyInternal = false
              this.data.showSurveyExternal = false
              this.data.url = null
            }
          } else {
            console.log('fail')
            this.data.showLoader = false
            this.data.showMessage = true
            this.data.showSurveyInternal = false
            this.data.showSurveyExternal = false
            this.data.url = null
          }
        })
        .catch(error => {
          console.log(`error: ${error}`)
          this.data.showLoader = false
          this.data.showMessage = true
          this.data.showSurveyInternal = false
          this.data.showSurveyExternal = false
        })
    },
    iframeLoaded() {
      this.data.showLoader = false
      this.data.showMessage = false
      this.data.showSurveyExternal = true
      this.data.showSurveyInternal = false
    },
    saveSurvey(payload) {
      axios
        .post('/survey/save', payload)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
